import { useState, useEffect  } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import '../assets/css/Login.css';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { green } from '@mui/material/colors';
import Form from 'react-bootstrap/Form';
import Button from '@mui/material/Button';
import InputGroup from 'react-bootstrap/InputGroup';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import axiosInstance from '../redux/axios.js';
import { useDispatch} from 'react-redux';
import { setAuth } from '../redux/reducers/authReducer';

export default function SignUp(){
    const [showPass, setShow] = useState(false);
    const [showPass1, setShow1] = useState(false);
    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
   
    const [telegram, setTelegram] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setCPassword] = useState('');
    const [wallet_address,setWalletAddress] = useState('');

    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const dispatch = useDispatch()

    useEffect(() => {
        setError('');
        setSuccess('');
    }, [first_name, last_name, wallet_address, telegram, password, confirmPassword])

    useEffect(() => {
        if(success !== ''){
            setError('');
        }
    }, [success])

    const navigate = useNavigate();

    const checkValidate = () => {
        return first_name !== '' && last_name !== '' 
         && telegram !== '' && password !== '' && confirmPassword !== '' && wallet_address !== '';
    }

    const register = async (e) => {
        e.preventDefault();

        if(checkValidate()){
            if(confirmPassword === password){
                try{
                    const formData = new FormData();
                    formData.append('first_name', first_name);
                    formData.append('last_name', last_name);
                    formData.append('password', password);
                    formData.append('telegram', telegram);
                    formData.append('wallet_address', wallet_address);

                    const result = await axiosInstance.post('user/register', JSON.stringify(Object.fromEntries(formData)), {
                        headers: {
                          'Content-Type': 'application/json'
                        }
                      });
                      
                    if(result?.data?.user){
                       dispatch(setAuth(result?.data))
                       setSuccess("User created successfully. Log in to your account");
                    }
                }catch(err) {
                    if(err?.response?.data){
                        const errs = err?.response?.data?.errors;
                        if(typeof errs === 'object' && errs !== null && !Array.isArray(errs)){
                            if(Object.keys(errs).length === 0){
                                if(err.response.data?.message){
                                    setError(err.response.data?.message)
                                }else{
                                    setError(err.response?.statusText)
                                }
                            }else{
                                setError(Object.values(errs).join(' ,'))
                            }
                        }else{
                            if(err.response.data?.message){
                                setError(err.response.data?.message)
                            }else{
                                setError(err.response?.statusText)
                            }
                        }
                    }
                }
            }else{
                setError("Password doesn't match")
            }
        }else{
            setError("Fill in the form")
        }
    }
    return(
        <div className="login_page">
            <Container maxWidth="sm">
                <Box
                display="flex"
                flexDirection="column"
                className="contact_form login_form"
                alignItems="center"
                backgroundColor="dark"
                padding="2rem 2rem"
                borderRadius="10px"
                >
                    <Avatar sx={{ bgcolor: green[100] }}>
                        <LockOutlinedIcon />
                    </Avatar>

                    <h3 className="pt-3 pb-1 mb-0">Sign Up</h3>
                    <p className="pb-2">Signup and unlock your online earnings</p>

                    <Form style={{width: '100%'}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Form.Group className="mb-3" controlId="registerForm.first_name">
                                    <Form.Label>First Name <span>*</span></Form.Label>
                                    <Form.Control value={first_name} onChange={(e) => setFirstName(e.target.value)} type="text" placeholder="" />
                                </Form.Group>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Form.Group className="mb-3" controlId="registerForm.last_name">
                                    <Form.Label>Last Name <span>*</span></Form.Label>
                                    <Form.Control value={last_name} onChange={(e) => setLastName(e.target.value)} type="text" placeholder="" />
                                </Form.Group>
                            </Grid>
                        </Grid>
                        <Form.Group className="mb-3" controlId="registerForm.telegram">
                            <Form.Label>Telegram Username <span>*</span></Form.Label>
                            <Form.Control value={telegram} onChange={(e) => setTelegram(e.target.value)} type="text" placeholder="@username" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="registerForm.wallet_address">
                            <Form.Label>Wallet address <span>*</span></Form.Label>
                            <Form.Control value={wallet_address} onChange={(e) => setWalletAddress(e.target.value)} type="text" placeholder="@username" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="registerForm.password">
                            <Form.Label>Password<span>*</span></Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control value={password} onChange={(e) => setPassword(e.target.value)} type={showPass ? "text" : "password"} placeholder="" />
                                <InputGroup.Text  onClick={() => {setShow(!showPass)}} style={{cursor: 'pointer'}}>{showPass ? <VisibilityIcon/> : <VisibilityOffIcon/>}</InputGroup.Text>
                            </InputGroup>
                           
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="registerForm.confirmPassword">
                            <Form.Label>Confirm Password<span>*</span></Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control value={confirmPassword} onChange={(e) => setCPassword(e.target.value)}  type={showPass1 ? "text" : "password"} placeholder="" />
                                <InputGroup.Text onClick={() => {setShow1(!showPass1)}} style={{cursor: 'pointer'}}>{showPass1 ? <VisibilityIcon/> : <VisibilityOffIcon/>}</InputGroup.Text>
                            </InputGroup>
                        </Form.Group>
                        <div className="display_start">
                            <Button type="submit" onClick={(e) => register(e)} className="btn_about full-width">Sign Up</Button>
                        </div>
                        {error && <p className="text_error">{error}</p>}
                        {success && <p className="text_success">{success}</p>}
                        
                        <div className="other_login pt-4">Already have an account? <span onClick={() => navigate('/login')}>Sign In</span></div>
                    </Form>
                </Box>
            </Container>
        </div>     
    )
}