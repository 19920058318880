function timeAgo(dateString) {
    const now = new Date();
    const date = new Date(dateString);

    const seconds = Math.floor((now - date) / 1000);
    const interval = Math.floor(seconds / 60);
    const intervalInHours = Math.floor(seconds / 3600);
    const intervalInDays = Math.floor(seconds / 86400);
    const intervalInMonths = Math.floor(intervalInDays / 30);
    const intervalInYears = Math.floor(intervalInDays / 365);

    if (intervalInYears > 1) {
        return `${intervalInYears} years ago`;
    } else if (intervalInYears === 1) {
        return `1 year ago`;
    } else if (intervalInMonths > 1) {
        return `${intervalInMonths} months ago`;
    } else if (intervalInMonths === 1) {
        return `1 month ago`;
    } else if (intervalInDays > 1) {
        return `${intervalInDays} days ago`;
    } else if (intervalInDays === 1) {
        return `1 day ago`;
    } else if (intervalInHours > 1) {
        return `${intervalInHours} hours ago`;
    } else if (intervalInHours === 1) {
        return `1 hour ago`;
    } else if (interval > 1) {
        return `${interval} minutes ago`;
    } else if (interval === 1) {
        return `1 minute ago`;
    } else {
        return `Just now`;
    }
}
  
function formatDateTime(dateTimeString) {
    const date = new Date(dateTimeString);

    // Extract date parts
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');

    // Extract time parts
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');

    // Determine AM/PM
    const ampm = hours >= 12 ? 'PM' : 'AM';

    // Convert to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // If hours is 0, set it to 12

    // Format the final string
    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes} ${ampm}`;
    
    return formattedDateTime;
}

const dateUtils = { timeAgo, formatDateTime };

export default dateUtils;