import './App.css';
import logo from './assets/img/TRUMP_LOGO.png';
import djt from './assets/img/DJT-WEBSITE.png';
import quotes from './assets/img/quotes.webp';
import axiosInstance from './redux/axios';
import { useParams } from 'react-router-dom';

function getGACID() {
  const cookie = document.cookie.split('; ').find(row => row.startsWith('_ga='));
  
  if (cookie) {
      const cookieValue = cookie.split('=')[1];
      return cookieValue.split('.').slice(2).join('.');
  }
  return null;
}

function Main() {
  const { ref_code } = useParams();

  const getLink = async (price) => {
    try{
      const gaCid = getGACID();

      let params = {
        gaCid: gaCid,
        price: price
      }

      if(ref_code){
        params = {
          gaCid: gaCid,
          price: price,
          ref_code: ref_code
        }
      }
      if (gaCid) {
        const response = await axiosInstance.get('payments/new', {
          params: params
        })


        if(response.data?.form){
          window.location.href = response.data?.form;
          //window.open(response.data?.form, '_blank', 'noopener,noreferrer')
        }

      }
    }catch(err){
      console.log(err)
    }
  }

  return (
    <div className="App">
      <header className="App-header">
        <div className="App-abs"/>
        
        <div className="App-banner">
          <div>
            <img className="App_logo" src={logo} alt="logo" />
            <img className="App_logo mt-2" src={djt} alt="logo" />

            <div className="text-1">
              <p>A Message from Donald J. Trump, the 45th President of the United States</p>
            </div>

            <div className="text-2">
            <p>
              <img className="text-quotes" src={quotes} alt="quotes" />
               The Left believes that by bombarding me with relentless investigations and pressuring my family and associates they can eventually break my spirit and force me to abandon our America First movement. 
              <img className="text-quotes" src={quotes} alt="quotes" />
            </p>
            </div>

            <div className="text-3">
              <p>LET ME BE AS CLEAR AS POSSIBLE:</p>
            </div>

            <div className="text-4">
              <p>I WILL NEVER STOP FIGHTING FOR YOU.</p>
            </div>

            <div className="text-5">
              <p>They’re not after me, they’re after you… I’m just standing in the way!</p>
            </div>

            <div className="text-2">
            <p>
              <img className="text-quotes" src={quotes} alt="quotes" />
              Friends, they’ve tried everything to stop me, even arresting me. This is a political attack to silence our movement. But we won’t be intimidated or silenced. We’ll keep figting for our country and our values. Together, we will overcome this and Make America Great Again! 
              <img className="text-quotes" src={quotes} alt="quotes" />
            </p>
            </div>

            <div className="text-6">
              <p>
                Donald J. Trump<br />45th President of the United States
              </p>
            </div>

            <div className="text-7">
              <p>
                SAVE AMERICA!
              </p>
            </div>

            <div className="text-8">
              <p><strong>The much-anticipated SWT has finally arrived.</strong></p>
              <p>Once you have secured it for yourself, you may also wish to acquire some for your family.</p>
              <p>Hardworking patriots like <strong>YOU</strong> are the backbone of our country and deserve the best.</p>
              <p><strong>MOST IMPORTANTLY</strong>, you might be wondering: How can you obtain more of it? First, choose the model you prefer, such as the <strong>SWT 2000. </strong>
              Upon selecting it, you will be redirected to a page where you must fill out some details. In Step 3 of that form, you will find the 
              <strong> Quantity Option,</strong> which allows you to select a larger amount. It is always prudent to acquire more when possible. Be wise and make the most of this opportunity.
              </p>
              <p>
              <span style={{fontSize: '30px'}}><strong>STAND WITH TRUMP</strong></span>
              </p>
              <p>
              After selecting one of the four options and being redirected to the Checkout page, there may be a special option available for 
              <strong> YOU </strong>
              under the Step 1 section. This option will not be available for long, so be sure to select it promptly!
              </p>
              <p>In conclusion, let us not delay any further.
                <strong><br/>Take action now!</strong>
              </p>
            </div>

            <div className="btn-red">
              <div onClick={() => getLink(500)}>SWT 500 ($500)</div>
            </div>

            <div className="btn-red">
              <div onClick={() => getLink(800)}>SWT 800 ($800)</div>
            </div>

            <div className="btn-red">
              <div onClick={() => getLink(1000)}>SWT 1000 ($1000)</div>
            </div>

            <div className="btn-red btn-green">
              <div onClick={() => getLink(2000)}>SWT 2000 ($2000)</div>
            </div>

          </div>
        </div>

        <div className="App-footer">
          <div className="text-footer">
            <p>
            This is a commemorative piece and is solely intended as memorabilia.
            <br/>
            © 2024 Official SWT. All Rights Reserved.
            </p>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Main;
