import * as React from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import ProfileComponent from '../components/ProfileComponent';
import ChangePassword from '../components/ChangePassword';
import Button from '@mui/material/Button';

import axiosInstance from '../redux/axios.js';
import { useDispatch} from 'react-redux';
import { logOutAuth } from '../redux/reducers/authReducer';
import { useNavigate } from 'react-router-dom';
import PurchaseTable from '../components/PurchaseTable';

export default function User({user}){
    const dispatch = useDispatch()
    const navigate = useNavigate();
    
    const logOut = async () => {
        try{
            const response = await axiosInstance.get('user/logout');
          
            if(response?.data?.message){
              dispatch(logOutAuth())
              navigate('/login');
            }
          }catch{
            dispatch(logOutAuth())
            navigate('/login');
          }
    }
    return(
        <div className="dashboard_page">
            <Container maxWidth="lg" className="pt-5">
                <Button type="submit" onClick={() => {logOut()}} style={{backgroundColor: 'red'}} className="btn_nocolor full-width">LogOut</Button>
            </Container>
            <Container maxWidth="lg" className="pt-5 pb-5">
                <Paper className="paper_card pt-3 pb-3 ps-3 pe-3" variant="elevation" elevation={6}>
                    <ProfileComponent />
                </Paper>
            </Container>

            <Container maxWidth="lg" className="pt-5 pb-5">
                <ChangePassword />
            </Container>

            <Container maxWidth="lg" className="pt-5 pb-5">
                <PurchaseTable user={user} />
            </Container>
        </div>
    )
}