
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Grid from '@mui/material/Grid';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import axiosInstance from '../redux/axios';
import { updateUserInfo } from '../redux/reducers/authReducer';
import { useDispatch} from 'react-redux';

export default function ProfileComponent({otherUser, disabled}){
    const {user} = useSelector(state => state.auth);
    const [tempUser, setTempUser] = useState({
        first_name: '',
        last_name: '',
        telegram: '',
        wallet_address: '',
    });
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const dispatch = useDispatch()

    useEffect(() => {
        if(otherUser){
            setTempUser({
                first_name: otherUser.first_name || '',
                last_name: otherUser.last_name || '',
                telegram: otherUser.telegram || '',
                wallet_address: otherUser.wallet_address || '',
            })
        }
        else if(!otherUser && user){
            setTempUser({
                first_name: user.first_name || '',
                last_name: user.last_name || '',
                telegram: user.telegram || '',
                wallet_address: user.wallet_address || '',
            })
        }
    }, [user, otherUser])

    useEffect(() => {
        setError('');
    }, [tempUser])

    useEffect(() => {
        if(success !== ''){
            setError('');
        }
    }, [success])

    useEffect(() => {
        if(error !== ''){
            setSuccess('');
        }
    }, [error])

    const checkValidate = () => {
        return Object.values(tempUser).every(value => value !== '');
    }
    
    const submit = async (e) => {
        e.preventDefault()
        if(checkValidate()){
            try{
                const formData = new FormData();
               
                for (let key in tempUser) {
                    if (tempUser.hasOwnProperty(key)) {
                        formData.append(key, tempUser[key]);
                    }
                }

                const result = await axiosInstance.put('user/'+(otherUser ? otherUser?._id : user?._id), JSON.stringify(Object.fromEntries(formData)), {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                if(result?.data){
                    setSuccess("User updated successfully");
                    dispatch(updateUserInfo(result?.data))
                }
            }catch(err) {
                if(err?.response?.data){
                    const errs = err?.response?.data?.errors;
                    if(typeof errs === 'object' && errs !== null && !Array.isArray(errs)){
                        if(Object.keys(errs).length === 0){
                            if(err.response.data?.message){
                                setError(err.response.data?.message)
                            }else{
                                setError(err.response?.statusText)
                            }
                        }else{
                            setError(Object.values(errs).join(' ,'))
                        }
                    }else{
                        if(err.response.data?.message){
                            setError(err.response.data?.message)
                        }else{
                            setError(err.response?.statusText)
                        }
                    }
                }
            }
        }else{
            setError("Fill in the form")
        }
    }

    return(
        <Form className="pt-4 pb-3">
             {error && <p className="text_error">{error}</p>}
             {success && <p className="text_success">{success}</p>}

            <Grid container className="pt-5" spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Form.Group className="mb-3" controlId="profileForm.FirstName">
                        <Form.Label>First Name</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control value={tempUser.first_name} onChange={e => setTempUser({...tempUser, first_name: e.target.value})} disabled={disabled} type="text" placeholder="" />
                        </InputGroup>  
                    </Form.Group>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Form.Group className="mb-3" controlId="profileForm.LastName">
                        <Form.Label>Last Name</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control value={tempUser.last_name} onChange={e => setTempUser({...tempUser, last_name: e.target.value})} disabled={disabled} type="text" placeholder="" />
                        </InputGroup>  
                    </Form.Group>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Form.Group className="mb-3" controlId="profileForm.id">
                        <Form.Label>ID</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control value={user ? user._id: ""} disabled type="text" placeholder="" />
                        </InputGroup>  
                    </Form.Group>
                </Grid>
                
                <Grid item xs={12} sm={6}>
                    <Form.Group className="mb-3" controlId="profileForm.Telegram">
                        <Form.Label>Telegram Username</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control value={tempUser.telegram} onChange={e => setTempUser({...tempUser, telegram: e.target.value})} disabled={disabled} type="text" placeholder="" />
                        </InputGroup> 
                    </Form.Group>
                </Grid>
            </Grid>


            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Form.Group className="mb-3" controlId="profileForm.State">
                        <Form.Label>Ref Code</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control value={user? user.ref_code : ''} disabled type="text" placeholder="" />
                        </InputGroup> 
                    </Form.Group>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Form.Group className="mb-3" controlId="profileForm.State">
                        <Form.Label>Ref Link</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control value={user? ('https://www.dtdonations.com/' + user.ref_code) : ''} disabled type="text" placeholder="" />
                        </InputGroup> 
                    </Form.Group>
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                    <Form.Group className="mb-3" controlId="profileForm.Wallet">
                        <Form.Label>Wallet Address</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control value={tempUser.wallet_address} onChange={e => setTempUser({...tempUser, wallet_address: e.target.value})} type="text" disabled={disabled} placeholder="" />
                        </InputGroup> 
                    </Form.Group>
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                    <Form.Group className="mb-3" controlId="profileForm.Wallet">
                        <Form.Label>Role</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control value={user?.role_id?.name || ''} type="text" disabled={true} placeholder="" />
                        </InputGroup> 
                    </Form.Group>
                </Grid>
            </Grid>
            
            {!disabled && 
            <>
            <div className="display_start">
                <Button onClick={(e) => submit(e)} type="submit" className="btn_about full-width">Update Profile</Button>
            </div>
           
            </>}
        </Form>
    )
}