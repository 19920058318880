import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    user: null,
    token: null
};

const authSlice = createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
        setAuth: (state, action) => {
            state.user = action.payload.user;
            state.token = action.payload.accessToken;
        },
        updateUserInfo: (state, action) => {
            state.user = action.payload;
        },
        logOutAuth: (state) => {
            state.user = null;
            state.token = null;
        },
    }
});

export const {setAuth, updateUserInfo, logOutAuth} = authSlice.actions;

export default authSlice.reducer;